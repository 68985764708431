import { Auth, Storage, Logger } from 'aws-amplify'
import axios from 'axios'
import notImg from '@/assets/img/not_img.jpg'
import momentDate from 'moment'
import LogoHeader from '@/assets/img/logo_header.png'

// import banner1 from '@/assets/img/banner/banner1.jpg'
// import banner2 from '@/assets/img/banner/banner2.jpg'
// import banner3 from '@/assets/img/banner/banner3.jpg'

export default {
  data: function () {
    return {
      globalTeamName: 'コンサドーレ札幌',
      globalTeamNameAbbreviation: 'CON',
      globalCompanyName: 'コンサドーレ札幌',
      globalClubType: 'サッカー',
      globalSchoolType: 'スクール',
      globalSchoolNameEn: 'CONSADOLE',
      globalSchoolTypeEn: 'SCHOOL',
      defTimeout: 10000000,
      releaseYear: 2020,
      linkCardIcon: 'icon-soccer-ball',
      linkUseOfWebsite: 'https://www.vegalta.co.jp/about-web.html',
      linkPrivacyPolicy: 'https://www.vegalta.co.jp/privacy-policy.html',
      linkOfficialBlog: 'https://www.vegalta.co.jp/blogs/academy/',
      linkOfficialSite: 'https://www.vegalta.co.jp/',
      schoolSecretariatTel: '0117775314',
      defaultLatitude: 38.260247,
      defaultLongitude: 140.879723,
      defaultMap: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      defaultAttribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      telMask: '###########',
      postalCodeMask: '###-####',
      idMask: '########',
      creditCardMask: '#### #### #### ####',
      temperatureMask: '##.#',
      mask7: '#######',
      mask4: '####',
      mask3: '###',
      mask2: '##',
      calendarMinHeight: 600,
      infiniteScrollRange: 100,
      temperatureWarningLine: 37,
      temperatureErrorLine: 38,
      wysuwygMaxLength: 10000,
      defNotImg: notImg,
      timeHourList: [...Array(24)].map((v, i) => ('00' + i).slice(-2)),
      timeMinList: [...Array(12)].map((v, i) => ('00' + (i * 5)).slice(-2)),
      localLoading: [],
      logoHeader: LogoHeader,
      weekDayList: [
        {
          value: 0,
          text: '日曜日',
          short: '（日）',
          one: '日',
          showOrder: 6,
          dbValue: 6
        },
        {
          value: 1,
          text: '月曜日',
          short: '（月）',
          one: '月',
          showOrder: 0,
          dbValue: 0
        },
        {
          value: 2,
          text: '火曜日',
          short: '（火）',
          one: '火',
          showOrder: 1,
          dbValue: 1
        },
        {
          value: 3,
          text: '水曜日',
          short: '（水）',
          one: '水',
          showOrder: 2,
          dbValue: 2
        },
        {
          value: 4,
          text: '木曜日',
          short: '（木）',
          one: '木',
          showOrder: 3,
          dbValue: 3
        },
        {
          value: 5,
          text: '金曜日',
          short: '（金）',
          one: '金',
          showOrder: 4,
          dbValue: 4
        },
        {
          value: 6,
          text: '土曜日',
          short: '（土）',
          one: '土',
          showOrder: 5,
          dbValue: 5
        }
      ],
      lessonMasterStatusList: [
        {
          value: 1,
          text: '受付中'
        },
        {
          value: 2,
          text: 'キャンセル待ち'
        },
        {
          value: 3,
          text: '募集停止'
        },
        {
          value: 4,
          text: '募集停止（年度更新時に選択可能）'
        },
        {
          value: 5,
          text: '募集停止（年度更新時にコーチのみ選択可能）'
        }
      ],
      requestTypeText: [
        null, '入会', '変更', '退会', '休会', '休会解除'
      ],
      statusText: [
        null, '申請中', '承認済み', 'キャンセル待ち', '取消済み', null, null, null, null, '非承認済み'
      ],
      eventTopList: [
        'eventTop', 'eventDetails', 'eventRequest', 'eventRequestCompleted'
      ],
      requestTypeItem: [
        {
          value: 1,
          text: '入会'
        },
        {
          value: 2,
          text: '変更'
        },
        {
          value: 3,
          text: '退会'
        },
        {
          value: 4,
          text: '休会'
        },
        {
          value: 5,
          text: '休会解除'
        }
      ],
      statusItem: [
        {
          value: 1,
          text: '申請中'
        },
        {
          value: 2,
          text: '承認済み'
        },
        {
          value: 3,
          text: 'キャンセル待ち'
        },
        {
          value: 4,
          text: '取消済み'
        },
        {
          value: 9,
          text: '非承認済み'
        }
      ],
      lessonStatusList: [
        '', '入会手続き中', '処理済み', 'キャンセル待ち', '取り消し済み', '変更手続き中', '退会手続き中', '休会手続き中', '休会解除手続き中', '否認'
      ],
      transferStatusItem: [
        {
          value: 1,
          text: '申請中'
        },
        {
          value: 2,
          text: '承認済み'
        },
        {
          value: 3,
          text: 'キャンセル済み'
        },
        {
          value: 9,
          text: '非承認済み'
        }
      ],
      positionList: [
        {
          text: 'フォワード(FW)',
          value: 0
        },
        {
          text: 'バックス(BK)',
          value: 1
        },
        {
          text: 'ディフェンダー(DF)',
          value: 2
        },
        {
          text: 'ゴールキーパー(GK)',
          value: 3
        }
      ],
      targetClassList: [
        {
          value: 0,
          text: '年少未満',
          age: 3
        },
        {
          value: 1,
          text: '年少',
          age: 4
        },
        {
          value: 2,
          text: '年中',
          age: 5
        },
        {
          value: 3,
          text: '年長',
          age: 6
        },
        {
          value: 4,
          text: '小学1年生',
          age: 7
        },
        {
          value: 5,
          text: '小学2年生',
          age: 8
        },
        {
          value: 6,
          text: '小学3年生',
          age: 9
        },
        {
          value: 7,
          text: '小学4年生',
          age: 10
        },
        {
          value: 8,
          text: '小学5年生',
          age: 11
        },
        {
          value: 9,
          text: '小学6年生',
          age: 12
        },
        {
          value: 10,
          text: '中学1年生',
          age: 13
        },
        {
          value: 11,
          text: '中学2年生',
          age: 14
        },
        {
          value: 12,
          text: '中学3年生',
          age: 15
        },
        {
          value: 13,
          text: '高校1年生',
          age: 16
        },
        {
          value: 14,
          text: '高校2年生',
          age: 17
        },
        {
          value: 15,
          text: '高校3年生',
          age: 18
        },
        {
          value: 16,
          text: '大学生以上',
          age: 19
        }
      ],
      amountStatusList: [
        {
          id: 0,
          value: 0,
          text: '未確認',
          color: 'warning',
          textColor: '#fff'
        },
        {
          id: 1,
          value: 1,
          text: '確認済み',
          color: 'info',
          textColor: '#fff'
        },
        {
          id: 2,
          value: 2,
          text: '締め済み',
          color: 'success',
          textColor: '#fff'
        },
        {
          id: 3,
          value: 3,
          text: '支払い済み',
          color: 'grey',
          textColor: '#fff'
        },
        {
          id: 4,
          value: 4,
          text: 'お支払い残高',
          color: 'error',
          textColor: '#fff'
        },
        {
          id: 5,
          value: 5,
          text: '振り替え済み',
          color: 'grey',
          textColor: '#fff'
        }
      ],
      updateStatusList: [
        {
          id: 1,
          value: 1,
          text: '未確認',
          color: 'warning',
          textColor: '#fff'
        },
        {
          id: 2,
          value: 2,
          text: '確認済み',
          color: 'info',
          textColor: '#fff'
        },
        {
          id: 3,
          value: 3,
          text: '締め済み',
          color: 'success',
          textColor: '#fff'
        }
      ],
      eventStatusList: [
        {
          id: 1,
          value: 4,
          text: '公開前',
          color: 'warning',
          textColor: '#fff',
          isAdminOnly: true
        },
        {
          id: 2,
          value: 1,
          text: '募集開始前',
          color: 'info',
          textColor: '#fff',
          isAdminOnly: false
        },
        {
          id: 3,
          value: 2,
          text: '募集中',
          color: 'success',
          textColor: '#fff',
          isAdminOnly: false
        },
        {
          id: 4,
          value: 3,
          text: '募集終了',
          color: 'grey',
          textColor: '#424242',
          isAdminOnly: false
        },
        {
          id: 5,
          value: 5,
          text: '開催終了',
          color: 'grey',
          textColor: '#424242',
          isAdminOnly: false
        },
        {
          id: 9,
          value: 9,
          text: '中止',
          color: 'error',
          textColor: '#fff',
          isAdminOnly: false
        }
      ],
      paymentTypeList: [
        {
          id: 1,
          value: 0,
          text: '支払い無し',
          color: 'grey',
          textColor: '#424242'
        },
        {
          id: 2,
          value: 2,
          text: '現地払い',
          color: 'warning',
          textColor: '#fff'
        },
        {
          id: 3,
          value: 1,
          text: '支払い済み',
          color: 'success',
          textColor: '#fff'
        },
        {
          id: 4,
          value: 7,
          text: '支払いキャンセル',
          color: 'grey',
          textColor: '#fff'
        },
        {
          id: 5,
          value: 8,
          text: '支払い処理中',
          color: 'info',
          textColor: '#fff'
        },
        {
          id: 6,
          value: 9,
          text: '支払いエラー',
          color: 'error',
          textColor: '#fff'
        }
      ],
      targetTypeList: [
        {
          value: 1,
          text: '会員限定',
          textColor: 'secondaryBtnText',
          color: 'secondary'
        },
        {
          value: 2,
          text: '全員',
          textColor: '#424242',
          color: 'grey'
        }
      ],
      linkData: [
        {
          title: '入会・体験',
          subTitle: 'Enrollment',
          text: 'ご入会登録がまだの方はこちら',
          icon: 'icon-foot-and-ball',
          color: 'topSecondaryBtn',
          fontColor: 'topSecondaryBtnText',
          href: '/enrollment',
          isOut: false,
          isJoined: false
        },
        {
          title: 'マイページ',
          subTitle: 'My page',
          text: 'ご入会済みの方はこちら',
          icon: 'icon-door',
          color: 'topPrimaryBtn',
          fontColor: 'topPrimaryBtnText',
          href: '/portal',
          isOut: false,
          isJoined: true
        }
      ],
      subLinkData: [
        // {
        //   title: '開催イベント一覧',
        //   color: 'secondary',
        //   fontColor: 'white',
        //   width: 920,
        //   height: 60,
        //   img: null,
        //   href: '/event',
        //   isOut: false,
        //   cols: 12,
        //   spCols: 12
        // },
        // {
        //   title: 'オフィシャルブログ',
        //   color: 'success',
        //   fontColor: 'white',
        //   width: 468,
        //   height: 60,
        //   img: null,
        //   href: 'https://www.google.com/',
        //   isOut: true,
        //   cols: 6,
        //   spCols: 12
        // },
        // {
        //   title: 'グッズ販売',
        //   color: '#bdb76b',
        //   fontColor: '#424242',
        //   width: 468,
        //   height: 60,
        //   href: 'https://www.yahoo.co.jp/',
        //   img: null,
        //   isOut: true,
        //   cols: 6,
        //   spCols: 12
        // }
        // {
        //   title: '',
        //   color: '',
        //   fontColor: '',
        //   width: 510,
        //   height: 72,
        //   href: 'https://www.google.com/search?q=%E5%A4%95%E6%96%B9&hl=ja&sxsrf=AOaemvI33v8TXkIUfG5nZAmtHHJL0d5Bmg%3A1630911978670&source=hp&ei=6r01YcLSJYHB-wTC_oWoBQ&iflsig=ALs-wAMAAAAAYTXL-pv9SjxzXUdSeEQFzJPkfVE7CCN8&oq=%E5%A4%95%E6%96%B9&gs_lcp=Cgdnd3Mtd2l6EAMyCQgAEIAEEAQQJTIECAAQQzIPCAAQgAQQsQMQsQMQBBAlMgkIABCABBAEECUyCQgAEIAEEAQQJTIICAAQsQMQgwEyFQgAEIAEELEDEIMBELEDEIMBEAQQJTIVCAAQgAQQsQMQgwEQsQMQgwEQBBAlOgcIIxDqAhAnOgQIIxAnOg0IABCABBCxAxCDARAEOgoIABCABBCxAxAEOhMIABCABBCxAxCDARCxAxCDARAEOhAIABCxAxCDARCxAxCDARBDOgoIABCxAxCxAxBDOgcIABCABBAEOg8IABCABBCxAxCDARAEEApQ-GJY_G9gnnFoBHAAeACAAW-IAcQGkgEDMC44mAEAoAEBsAEK&sclient=gws-wiz&ved=0ahUKEwjC75TJ5OnyAhWB4J4KHUJ_AVUQ4dUDCAk&uact=5',
        //   img: banner1,
        //   isOut: true,
        //   cols: 4,
        //   spCols: 12
        // },
        // {
        //   title: '',
        //   color: '',
        //   fontColor: '',
        //   width: 510,
        //   height: 72,
        //   href: 'https://www.google.com/search?q=%E3%81%99%E3%81%99%E3%81%8D&hl=ja&sxsrf=AOaemvJAo8Cn-i4vByjy1Y2y_Gq9Ykp_7Q%3A1630911994173&ei=-r01YZPnCceHr7wP1tmy6Ac&oq=%E3%81%99%E3%81%99%E3%81%8D&gs_lcp=Cgdnd3Mtd2l6EAMyBwgAEIAEEAQyBwgAEIAEEAQyBwgAEIAEEAQyBwgAEIAEEAQyBwgAEIAEEAQyBwgAEIAEEAQyBwgAEIAEEAQyBwgAEIAEEAQ6BAgjECc6CggAEIAEELEDEAQ6DQgAEIAEELEDEIMBEAQ6BggAEAQQAzoNCAAQgAQQsQMQsQMQBDoICAAQsQMQgwFKBAhBGABQ3IoBWNyRAWDplAFoAHACeACAAXGIAfgEkgEDMC42mAEAoAEBwAEB&sclient=gws-wiz&ved=0ahUKEwiTzMnQ5OnyAhXHw4sBHdasDH0Q4dUDCA4&uact=5',
        //   img: banner2,
        //   isOut: true,
        //   cols: 4,
        //   spCols: 12
        // },
        // {
        //   title: '',
        //   color: '',
        //   fontColor: '',
        //   width: 510,
        //   height: 72,
        //   href: 'https://www.google.com/search?q=%E3%83%90%E3%83%A9&hl=ja&sxsrf=AOaemvKu_Zs4PWAXjw--0x-emYOuesBraw%3A1630912013996&ei=Db41YbmaPJXemAWL2rroAg&oq=%E3%83%90%E3%83%A9&gs_lcp=Cgdnd3Mtd2l6EAMyCggAEIAEEEYQ-wEyBwgAEIAEEAQyDQgAEIAEELEDELEDEAQyBwgAEIAEEAQyCAgAELEDEIMBMgoIABCABBCxAxAEMgoIABCABBCxAxAEMgcIABCABBAEOgcIABBHELADOgkIIxAnEEYQ_wE6DQgAEIAEELEDEIMBEAQ6BggAEAQQA0oECEEYAFDkWViLXmDUYWgBcAJ4AIABcogBkASSAQMwLjWYAQCgAQHIAQbAAQE&sclient=gws-wiz&ved=0ahUKEwj51IPa5OnyAhUVL6YKHQutDi0Q4dUDCA4&uact=5',
        //   img: banner3,
        //   isOut: true,
        //   cols: 4,
        //   spCols: 12
        // }
      ],
      myPortalLinkData: [
        // {
        //   title: 'オフィシャルブログ',
        //   color: 'success',
        //   fontColor: 'white',
        //   width: 468,
        //   height: 60,
        //   img: null,
        //   href: 'https://www.google.com/',
        //   isOut: true
        // },
        // {
        //   title: 'グッズ販売',
        //   color: '',
        //   fontColor: '',
        //   width: 468,
        //   height: 60,
        //   href: 'https://www.google.com/',
        //   img: banner1,
        //   isOut: true
        // },
        // {
        //   title: 'イベント一覧',
        //   color: '#bdb76b',
        //   fontColor: '#424242',
        //   width: 468,
        //   height: 60,
        //   href: 'enrollment',
        //   img: null,
        //   isOut: false
        // }
      ],
      joinFormLayout: [
        {
          title: '保護者氏名',
          example: 'コンサドーレ太郎',
          type: 'text',
          required: true,
          annotation: '',
          model: 'adminName'
        },
        {
          title: '保護者氏名フリガナ',
          example: 'コンサドーレタロウ',
          type: 'furigana',
          required: true,
          annotation: '',
          model: 'adminFurigana'
        },
        {
          title: '電話番号（ハイフン無し）',
          example: '00000000000',
          type: 'tel',
          required: true,
          annotation: '',
          model: 'tel'
        },
        {
          title: '緊急連絡先 電話番号',
          example: '00000000000',
          type: 'tel',
          required: true,
          annotation: '',
          model: 'emergencyTel'
        },
        {
          type: 'line'
        },
        {
          title: '郵便番号',
          example: '000-0000',
          type: 'postalCode',
          required: true,
          annotation: '郵便番号を入力すると、都道府県などが自動で入力されます。',
          model: 'postalCode'
        },
        {
          title: '都道府県',
          example: '北海道',
          type: 'prefectures',
          required: true,
          annotation: '',
          model: 'prefecture'
        },
        {
          title: '市区町村（区市町村）',
          example: '札幌市',
          type: 'cities',
          required: true,
          annotation: '',
          model: 'address'
        },
        {
          title: '番地・建物名',
          example: '0-0-0 コンサドーレマンション 000号室',
          type: 'housenumber',
          required: true,
          annotation: '',
          model: 'housenumber'
        },
        {
          type: 'line'
        },
        {
          title: '支払方法',
          example: '',
          type: 'payment',
          required: true,
          annotation: '',
          model: 'payment'
        }
      ],
      trialFormLayout: [
        {
          title: '氏名',
          example: 'コンサドーレ太郎',
          type: 'name',
          required: true,
          annotation: '',
          model: 'name',
          show: '0'
        },
        {
          title: '氏名フリガナ',
          example: 'コンサドーレタロウ',
          type: 'furigana',
          required: true,
          annotation: '',
          model: 'furigana',
          show: '0'
        },
        {
          title: '生年月日',
          example: '20200303',
          type: 'date',
          required: true,
          annotation: '',
          model: 'birthday',
          show: 'all'
        },
        {
          title: '電話番号（ハイフン無し）',
          example: '00000000000',
          type: 'tel',
          required: true,
          annotation: '',
          model: 'tel',
          show: 'all'
        },
        {
          title: '性別',
          example: '',
          type: 'gender',
          required: true,
          annotation: '',
          model: 'gender',
          show: 'all'
        },
        {
          title: '学校名または職場名',
          example: 'コンサドーレ小学校',
          type: 'schoolOrJob',
          required: false,
          annotation: '',
          model: 'schoolOrJob',
          show: 'all'
        }
        // {
        //   title: '希望ポジション',
        //   example: '',
        //   type: 'position',
        //   required: false,
        //   annotation: '',
        //   model: 'position',
        //   show: 'all'
        // }
      ],
      eventFormLayout: [
        {
          title: '氏名',
          example: 'ぴあ太郎',
          type: 'text',
          required: true,
          annotation: '',
          model: 'adminName'
        },
        {
          title: 'フリガナ',
          example: 'ピアタロウ',
          type: 'furigana',
          required: true,
          annotation: '',
          model: 'adminFurigana'
        },
        {
          title: '電話番号（ハイフン無し）',
          example: '00000000000',
          type: 'tel',
          required: true,
          annotation: '',
          model: 'tel'
        },
        {
          title: 'メールアドレス',
          example: 'example@email.co.jp',
          type: 'mail',
          required: true,
          annotation: '',
          model: 'mail'
        },
        {
          title: 'メールアドレス確認',
          example: 'example@email.co.jp',
          type: 'mailconfirm',
          required: true,
          annotation: '',
          model: 'mailconfirm'
        }
      ],
      eventStudentFormLayout: [
        {
          title: '氏名',
          example: 'ぴあ太郎',
          type: 'name',
          required: true,
          annotation: '',
          model: 'name',
          show: '0'
        },
        {
          title: 'フリガナ',
          example: 'ピアタロウ',
          type: 'furigana',
          required: true,
          annotation: '',
          model: 'furigana',
          show: '0'
        },
        {
          title: '生年月日',
          example: '20200303',
          type: 'date',
          required: true,
          annotation: '',
          model: 'birthday',
          show: 'all'
        },
        {
          title: '性別',
          example: '',
          type: 'gender',
          required: true,
          annotation: '',
          model: 'gender',
          show: 'all'
        }
      ],
      genderChips: [
        {
          id: 'gender-0',
          text: '全体',
          color: 'success',
          textColor: 'white'
        },
        {
          id: 'gender-1',
          text: '男性',
          color: 'info',
          textColor: 'white'
        },
        {
          id: 'gender-2',
          text: '女性',
          color: 'error',
          textColor: 'white'
        },
        {
          id: 'gender-3',
          text: '無回答',
          color: 'grey',
          textColor: 'white'
        }
      ],
      reasonType: [
        {
          value: 1,
          text: '自主都合'
        },
        {
          value: 2,
          text: 'スクール都合'
        },
        {
          value: 3,
          text: '学校行事'
        },
        {
          value: 4,
          text: 'その他'
        }
      ],
      coachContactReasonType: [
        {
          value: 1,
          text: '欠席'
        },
        {
          value: 2,
          text: '遅刻'
        },
        {
          value: 3,
          text: '早退'
        },
        {
          value: 4,
          text: 'その他'
        }
      ],
      conditionSafetyColorList: [
        {
          value: 1,
          color: 'success'
        },
        {
          value: 2,
          color: 'warning'
        },
        {
          value: 3,
          color: 'error'
        }
      ],
      annualUpdateCsvStatusList: [
        {
          value: 1,
          text: '切替'
        },
        {
          value: 2,
          text: '継続'
        },
        {
          value: 3,
          text: '退会'
        },
        {
          value: 4,
          text: '休会'
        },
        {
          value: 5,
          text: 'キャンセル待ち'
        }
      ],
      fileMimeTypeList: [
        {
          value: 'jpg',
          type: 'image/jpeg'
        },
        {
          value: 'jpeg',
          type: 'image/jpeg'
        },
        {
          value: 'png',
          type: 'image/png'
        },
        {
          value: 'gif',
          type: 'image/gif'
        },
        {
          value: 'bmp',
          type: 'image/bmp'
        },
        {
          value: 'heic',
          type: 'image/heif'
        },
        {
          value: 'mp4',
          type: 'video/mp4'
        },
        {
          value: 'avi',
          type: 'video/x-msvideo'
        },
        {
          value: 'mov',
          type: 'video/quicktime'
        },
        {
          value: 'wmv',
          type: 'video/x-ms-wmv'
        },
        {
          value: 'flv',
          type: 'video/x-flv'
        },
        {
          value: 'webm',
          type: 'video/webm'
        },
        {
          value: 'm4v',
          type: 'video/mp4'
        },
        {
          value: 'hevc',
          type: 'image/heif'
        },
        {
          value: 'txt',
          type: 'text/plain'
        },
        {
          value: 'pdf',
          type: 'application/pdf'
        },
        {
          value: 'doc',
          type: 'application/msword'
        },
        {
          value: 'docx',
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        },
        {
          value: 'xls',
          type: 'application/vnd.ms-excel'
        },
        {
          value: 'xlsx',
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        {
          value: 'ppt',
          type: 'application/vnd.ms-powerpoint'
        },
        {
          value: 'pptx',
          type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        }
      ],
      approvalImageExtension: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'heic'],
      approvalExtension: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'heic', 'mp4', 'avi', 'mov', 'wmv', 'flv', 'webm', 'm4v', 'hevc', 'txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
      fileAccept: '.jpg, .jpeg, .png, .gif, .bmp, .heic, .mp4, .avi, .mov, .wmv, .flv, .webm, .m4v, .hevc, .txt, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx',
      imgAccept: '.jpg, .jpeg, .png, .gif, .bmp, .heic',
      pattern: {
        mail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        furigana: /^[ァ-ヴー・\s]+$/,
        password: /^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,20}$/,
        creditHolder: /^[0-9A-Za-z\-\\.,\s/]+$/,
        notSymbol: /^.*[!-/:-@¥[-`{-~]+.*$/,
        halfSpace: / /
      },
      errorMessage: {
        unknown: '時間をおいてからお試しください',
        required: '必須入力です',
        dateVald: '開始日時は終了日時よりも過去を入力してください',
        idCounter: 'idを正しく入力してください',
        furigana: '全角カタカナで入力してください',
        mail: 'メールアドレスは正しい形式で入力してください',
        idPass: 'メールアドレス、またはパスワードが間違っています',
        registedMail: 'そのメールアドレスは既に登録されています',
        passEasy: 'パスワードが簡単すぎるか、長すぎます 8～20文字、かつ半角英数字（大、小）、記号をそれぞれ1種類以上含むように入力してください。',
        confirmCode: '確認コードが間違っています',
        length50: '50文字以内で入力してください。',
        mailEqual: 'メールアドレスが一致しません',
        passwordEqual: 'パスワードが一致しません',
        inputProblem: '入力内容に問題があります',
        contactFileSize: 'アップロードできるファイルサイズは合計で10MBまでです',
        lengthOver: '文字数が多すぎます',
        creditCardLength: 'カード番号は正しく入力してください',
        yearLength: '4桁もしくは2桁で入力してください',
        securityCodeLength: '3桁もしくは4桁で入力してください',
        creditHolder: '使用できない文字が含まれています',
        monthMax: '月は正しく入力してください',
        lengthWrong: '桁数が違います',
        notSymbol: '半角記号は入力できません',
        halfSpace: '半角スペースは入力できません。',
        extension: 'アップロードが許可されていない拡張子が含まれています'
      },
      rules: {
        required: value => (typeof (value) === 'string' ? !!value.replace(/\s+/g, '') : (!!value || value === 0)) || this.errorMessage.required,
        requiredSelect: value => value !== null || this.errorMessage.required,
        requiredAutocomplete: value => !(value.length === 0) || this.errorMessage.required,
        idCounter: value => (value.length === 8 || value.length === 0) || this.errorMessage.idCounter,
        length3: value => (!value || (!!value && value.length === 3)) || this.errorMessage.lengthWrong,
        length4: value => (!value || (!!value && value.length === 4)) || this.errorMessage.lengthWrong,
        length7: value => (!value || (!!value && value.length === 7)) || this.errorMessage.lengthWrong,
        maxLength2: value => (!value || (!!value && value.toString().length <= 2)) || this.errorMessage.lengthOver,
        maxLength10: value => (!value || (!!value && value.toString().length <= 10)) || this.errorMessage.lengthOver,
        maxLength50: value => (!value || (!!value && value.toString().length <= 50)) || this.errorMessage.lengthOver,
        maxLength100: value => (!value || (!!value && value.toString().length <= 100)) || this.errorMessage.lengthOver,
        maxLength150: value => (!value || (!!value && value.toString().length <= 150)) || this.errorMessage.lengthOver,
        maxLength255: value => (!value || (!!value && value.toString().length <= 255)) || this.errorMessage.lengthOver,
        sizeLimit1GB: value => (!value || (!!value && value.size < 1000000000)) || this.errorMessage.sizeLimit1GB,
        sizeLimit3GB: value => (!value || (!!value && value.size < 3000000000)) || this.errorMessage.sizeLimit3GB,
        creditCardLength: value => (!value || (!!value && value.replace(/\s+/g, '').length === 16)) || this.errorMessage.creditCardLength,
        securityCodeLength: value => (!value || (!!value && (value.length === 3 || value.length === 4))) || this.errorMessage.securityCodeLength,
        yearLength: value => (!value || (!!value && (value.length === 2 || value.length === 4))) || this.errorMessage.yearLength,
        monthMax: value => (!value || (!!value && (parseInt(value) <= 12))) || this.errorMessage.monthMax,
        furigana: value => {
          const pattern = this.pattern.furigana
          return pattern.test(value) || this.errorMessage.furigana
        },
        email: value => {
          const pattern = this.pattern.mail
          return pattern.test(value) || this.errorMessage.mail
        },
        password: value => {
          const pattern = this.pattern.password
          return pattern.test(value) || this.errorMessage.passEasy
        },
        creditHolder: value => {
          const pattern = this.pattern.creditHolder
          return pattern.test(value) || this.errorMessage.creditHolder
        },
        notSymbol: value => {
          const pattern = this.pattern.notSymbol
          return (!value || (!!value && !pattern.test(value))) || this.errorMessage.notSymbol
        },
        notHalfSpace: value => {
          const pattern = this.pattern.halfSpace
          return (!value || (!!value && !pattern.test(value))) || this.errorMessage.halfSpace
        },
        extension: value => {
          const extensionLength = value[0] ? value[0].name.split('.').length : 0
          return (!value[0] || (!!value[0] && extensionLength !== 1 && this.approvalImageExtension.includes(value[0].name.split('.')[extensionLength - 1].toLowerCase()))) || this.errorMessage.extension
        }
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'video'] // ['link', 'image', 'video']
          ]
        }
      },
      editorOptionSp: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 1 }, { header: 2 }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'video']
          ]
        }
      }
    }
  },
  filters: {
    globalMomentYmd: function (date) {
      return date.format('YYYY-MM-DD')
    }
  },
  computed: {
  },
  mounted: function () {
    const self = this
    setTimeout(function () {
      self.globalPageBodyResize()
      window.addEventListener('resize', this.globalPageBodyResize)
    }, 100)
  },
  watch: {
  },
  methods: {
    globalPageBodyResize: async function () {
      if (Object.keys(this.$refs).includes('mainContent')) {
        this.$store.commit('setMaincontentEl', {
          maincontent: this.$refs.mainContent
        })
      }
      if (Object.keys(this.$refs).includes('page')) {
        this.$store.commit('setPageEl', {
          page: this.$refs.page,
          pageHeader: Object.keys(this.$refs).includes('pageHeader') ? this.$refs.pageHeader : null,
          pageBody: Object.keys(this.$refs).includes('pageBody') ? this.$refs.pageBody : null,
          pageFooter: Object.keys(this.$refs).includes('pageFooter') ? this.$refs.pageFooter : null
        })
      }
      return true
    },
    globalIsSp: function () {
      return window.innerWidth < this.$vuetify.display.thresholds.sm
    },
    globalIsApple: function () {
      return window.navigator.userAgent.match(/iPhone|iPad.+Mobile/)
    },
    globalLoginConfirm: async function () {
      return Auth.currentAuthenticatedUser()
    },
    globalLogout: async function () {
      const self = this
      this.$store.commit('startLoading')
      this.$store.commit('setMyCourse', [])
      console.log(this.$store.state.loginId)
      if (this.$store.state.loginId.match(/jleague/)) {
        window.localStorage.setItem('jleagueLogout', 'logout')
        window.location.href = process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN_LOGOUT_URL + '-alt?client_id=' + encodeURIComponent(process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN_CLIENT_ID)
      } else {
        Auth.signOut().then(response => {
          const snackBarInfo = {
            text: 'ログアウトしました。',
            color: 'success'
          }
          self.$store.commit('setSnackbar', snackBarInfo)
          self.$store.commit('setLogined', false)
          self.$store.commit('setLogout')
          self.$store.commit('setInitUserStatus')
          self.$store.commit('finishLoading')
        })
      }
    },
    globalLogoutNoSnack: async function () {
      const self = this
      this.$store.commit('startLoading')
      this.$store.commit('setMyCourse', [])
      if (this.$store.state.loginId.match(/jleague/)) {
        window.localStorage.setItem('jleagueLogout', 'noSnack')
        window.location.href = process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN_LOGOUT_URL + '-alt?client_id=' + encodeURIComponent(process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN_CLIENT_ID)
      } else {
        Auth.signOut().then(response => {
          self.$store.commit('setLogined', false)
          self.$store.commit('setLogout')
          self.$store.commit('setInitUserStatus')
          self.$store.commit('finishLoading')
        })
      }
    },
    globalSimpleLogout: function () {
      const self = this
      this.$store.commit('startLoading')
      Auth.signOut().then(response => {
        self.$store.commit('setLogined', false)
        self.$store.commit('setLogout')
        self.$store.commit('finishLoading')
      })
    },
    globalJleagueLogin: function () {
      const provider = process.env.VUE_APP_AWS_COGNITO_JLEAGUE_LOGIN
      console.log(provider)
      return Auth.federatedSignIn({ provider }).then(response => {
        console.log(response)
      })
    },
    globalGetData: function () {
      const self = this
      return self
    },
    globalAutoReplaceLine: function (text) {
      if (!text) {
        return ''
      }
      const textArray = text.split(' ')
      return textArray.map(v => '<span class="auto-new-line">' + v + '</span>').join('')
    },
    globalInfiniteHandler: function (el, pageLoad) {
      const pageBodyHeigt = el.children[0].clientHeight - el.clientHeight - this.infiniteScrollRange
      if (el.scrollTop >= pageBodyHeigt) {
        pageLoad()
      }
    },
    globalInfiniteHandlerSp: function (pageLoad) {
      if (!this.$refs.pageBody) {
        return true
      }
      const pageBodyHeigt = this.$refs.pageBody.clientHeight - window.outerHeight - this.infiniteScrollRange
      if (window.scrollY >= pageBodyHeigt) {
        pageLoad()
      }
    },
    globalUrlMatchPortal: function (url) {
      return url.match('portal') ? url.match('portal').length > 0 : false
    },
    // 年月と日から年月日を作成
    globalCreateFullDate: function (yearmonth, date) {
      const returnValue = yearmonth + '-' + ('00' + date).slice(-2)

      return returnValue
    },
    // 年月データから表示用のデータに変更する。
    globalYearMonthText: function (yearmonth) {
      return yearmonth.split('-')[0] + '年 ' + parseInt(yearmonth.split('-')[1]) + '月'
    },
    // 年月日データから表示用の年月日に変換する。
    globalYearMonthDayText: function (dateString) {
      return dateString ? this.moment(dateString).format('YYYY年 M月 D日') : '-'
    },
    // moment型から年月日に変換
    globalDateChangeString: function (date) {
      return date.format('YYYY-MM-DD')
    },
    // 年月日からmoment型に変換
    globalStringChangeDate: function (dateString) {
      // ブラウザが理解しやすい形に変形
      return this.moment(dateString)
    },
    globalBirthdayChangeGakunen: function (dateString) {
      const birthdate = this.moment(dateString)
      const today = this.moment()

      const targetYear = (today.month() + 1) >= 4 ? today.year() + 1 : today.year()
      const targetDate = this.moment(targetYear + '-04-01')

      // 生年月日
      const y2 = birthdate.year().toString().padStart(4, '0')
      const m2 = (birthdate.month() + 1).toString().padStart(2, '0')
      const d2 = birthdate.date().toString().padStart(2, '0')

      // 3/31
      const y1 = targetDate.year().toString().padStart(4, '0')
      const m1 = (targetDate.month() + 1).toString().padStart(2, '0')
      const d1 = targetDate.date().toString().padStart(2, '0')

      // today
      const y = today.year().toString().padStart(4, '0')
      const m = (today.month() + 1).toString().padStart(2, '0')
      const d = today.date().toString().padStart(2, '0')

      // 引き算
      const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000)
      const manAge = Math.floor((Number(y + m + d) - Number(y2 + m2 + d2)) / 10000)

      return age ? ((age < 4 || age > 18) ? manAge + '歳' : this.targetClassList.find(v => v.age === age).text) : '生年月日未登録'
    },
    // 学年を数値でかえす。
    globalBirthdayChangeGakunenReturnNum: function (dateString) {
      const birthdate = this.moment(dateString)
      const today = this.moment()

      const targetYear = (today.month() + 1) >= 4 ? today.year() + 1 : today.year()
      const targetDate = this.moment(targetYear + '-04-01')

      // 生年月日
      const y2 = birthdate.year().toString().padStart(4, '0')
      const m2 = (birthdate.month() + 1).toString().padStart(2, '0')
      const d2 = birthdate.date().toString().padStart(2, '0')

      // 3/31
      const y1 = targetDate.year().toString().padStart(4, '0')
      const m1 = (targetDate.month() + 1).toString().padStart(2, '0')
      const d1 = targetDate.date().toString().padStart(2, '0')

      // today
      const y = today.year().toString().padStart(4, '0')
      const m = (today.month() + 1).toString().padStart(2, '0')
      const d = today.date().toString().padStart(2, '0')

      // 引き算
      const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000)
      const manAge = Math.floor((Number(y + m + d) - Number(y2 + m2 + d2)) / 10000)

      return age ? ((age < 4 || age > 18) ? manAge : age) : 0
    },
    // 日付時点での学年を数値でかえす。
    globalBirthdayChangeGakunenReturnNumNotToday: function (dateString, todayString) {
      const birthdate = this.moment(dateString)
      const today = this.moment(todayString)

      const targetYear = (today.month() + 1) >= 4 ? today.year() + 1 : today.year()
      const targetDate = this.moment(targetYear + '-04-01')

      // 生年月日
      const y2 = birthdate.year().toString().padStart(4, '0')
      const m2 = (birthdate.month() + 1).toString().padStart(2, '0')
      const d2 = birthdate.date().toString().padStart(2, '0')

      // 3/31
      const y1 = targetDate.year().toString().padStart(4, '0')
      const m1 = (targetDate.month() + 1).toString().padStart(2, '0')
      const d1 = targetDate.date().toString().padStart(2, '0')

      // today
      const y = today.year().toString().padStart(4, '0')
      const m = (today.month() + 1).toString().padStart(2, '0')
      const d = today.date().toString().padStart(2, '0')

      // 引き算
      const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000)
      const manAge = Math.floor((Number(y + m + d) - Number(y2 + m2 + d2)) / 10000)

      return age ? ((age < 4 || age > 18) ? manAge : age) : 0
    },
    // コースのセレクトアイテムを取得する
    globalAllCourseSelectItemNotUi: async function (selSchool, selCourse, selClass, selLesson, restriction) {
      const self = this
      let relationMaster = []
      const schoolList = []
      const courseList = []
      const classList = []
      const lessonList = []
      let restrictionList = []

      await Promise.all([
        (async () => {
          if (restriction) {
            if (this.$store.state.myCourse === null) {
              await this.getMyCourse().then(response => {
                self.$store.commit('setMyCourse', response.lessons)
              })
            }
          }
          return restriction
        })(),
        (async () => {
          if (Object.keys(self.$store.state.lessonMaster).length === 0) {
            await self.getLessonMaster().then(response => {
              self.$store.commit('setLessonMaster', response.master)
            })
          }
          return self.$store.state.lessonMaster
        })(),
        (async () => {
          if (Object.keys(self.$store.state.lessonRelation).length === 0) {
            await self.getLessonRelationMaster().then(relation => {
              self.$store.commit('setLessonRelation', relation.master.enable_lessons)
            })
          }
          return self.$store.state.lessonRelation
        })()
      ])

      restrictionList = restriction ? this.$store.state.myCourse.map(v => v.school_id + '/' + v.course_id + '/' + v.class_id + '/' + v.lesson_id) : []
      const master = this.$store.state.lessonMasterAll
      const relation = this.$store.state.lessonRelationAll

      relationMaster = relation.filter(v => {
        let schoolF = false
        let courseF = false
        let classF = false
        let lessonF = false
        let restrictionF = true

        if (!selSchool || parseInt(selSchool) === v.school_id) {
          schoolF = true
        }

        if (!selCourse || parseInt(selCourse) === v.course_id) {
          courseF = true
        }
        if (!selClass || parseInt(selClass) === v.class_id) {
          classF = true
        }
        if (!selLesson || parseInt(selLesson) === v.lesson_id) {
          lessonF = true
        }
        if (restrictionList.length > 0) {
          restrictionF = restrictionList.filter(w => w === v.school_id + '/' + v.course_id + '/' + v.class_id + '/' + v.lesson_id).length > 0
        }
        return schoolF && courseF && classF && lessonF && restrictionF
      })

      await Promise.all(master.school.map(v => {
        if (relationMaster.some(w => w.school_id === v.school_id)) {
          schoolList.push({
            value: v.school_id,
            text: v.school_name,
            place: v.facility_name
          })
        }
        return v
      }))

      await Promise.all(master.course.map(v => {
        if (relationMaster.some(w => w.course_id === v.course_id)) {
          courseList.push({
            value: v.course_id,
            text: v.course_name
          })
        }
        return v
      }))

      await Promise.all(master.class.sort((a, b) => {
        let returnNum
        const aGradeFrom = a.target_grade_from
        const bGradeFrom = b.target_grade_from
        const aFromToDiff = a.target_grade_to - a.target_grade_from
        const bFromToDiff = b.target_grade_to - b.target_grade_from
        if (aGradeFrom === bGradeFrom) {
          if (aFromToDiff === bFromToDiff) {
            returnNum = a.class_id > b.class_id ? 1 : -1
          } else {
            returnNum = aFromToDiff > bFromToDiff ? 1 : -1
          }
        } else {
          returnNum = aGradeFrom > bGradeFrom ? 1 : -1
        }
        return returnNum
      }).map(v => {
        if (relationMaster.some(w => w.class_id === v.class_id)) {
          classList.push({
            value: v.class_id,
            text: v.class_name,
            targetFrom: v.target_grade_from,
            targetTo: v.target_grade_to
          })
        }
        return v
      }))

      await Promise.all(master.lesson.sort((a, b) => {
        let returnNum = 0
        const aDayOne = a.lessons_day.length !== 0 ? a.lessons_day[0].day_id : 0
        const bDayOne = b.lessons_day.length !== 0 ? b.lessons_day[0].day_id : 0
        const aDaysLength = a.lessons_day.length
        const bDaysLength = b.lessons_day.length
        const aDays = a.lessons_day.map(v => v.day_id).join(',')
        const bDays = b.lessons_day.map(v => v.day_id).join(',')
        const aIsOr = a.lessons_day.map(v => v.is_target).includes(0)
        const bIsOr = b.lessons_day.map(v => v.is_target).includes(0)
        const aTrainingTime = a.training_start_time.split(':').map(v => ('00' + v).slice(-2)).join(':') + '~' + a.training_end_time.split(':').map(v => ('00' + v).slice(-2)).join(':')
        const bTrainingTime = b.training_start_time.split(':').map(v => ('00' + v).slice(-2)).join(':') + '~' + b.training_end_time.split(':').map(v => ('00' + v).slice(-2)).join(':')
        if (aDayOne === bDayOne) {
          if (aDaysLength === bDaysLength) {
            if (aDays === bDays) {
              if (aIsOr === bIsOr) {
                returnNum = aTrainingTime > bTrainingTime ? 1 : -1
              } else {
                returnNum = aIsOr ? 1 : -1
              }
            } else {
              returnNum = aDays > bDays ? 1 : -1
            }
          } else {
            returnNum = aDaysLength > bDaysLength ? 1 : -1
          }
        } else {
          returnNum = aDayOne > bDayOne ? 1 : -1
        }
        return returnNum
      }).map(v => {
        if (relationMaster.some(w => w.lesson_id === v.lesson_id)) {
          lessonList.push({
            value: v.lesson_id,
            text: v.lesson_name + ' ' + v.training_time,
            trainingTime: v.training_time,
            name: v.lesson_name
          })
        }
        return v
      }))

      return {
        school: schoolList,
        course: courseList,
        class: classList,
        lesson: lessonList
      }
    },
    // コースのセレクトアイテムを取得する
    // mode = 0 : 全て
    // mode = 1 : 募集停止をすべて除く
    // mode = 2 : 生徒側が年度更新のみ選択可能もの
    // mode = 3 : 年度更新でコーチが選択可能なもの
    globalAllCourseSelectItem: async function (selSchool, selCourse, selClass, selLesson, restriction, mode = 0) {
      const self = this
      let schoolRelationMaster = []
      let courseRelationMaster = []
      let classRelationMaster = []
      let lessonRelationMaster = []
      const schoolList = []
      const courseList = []
      const classList = []
      const lessonList = []
      let restrictionList = []

      await Promise.all([
        (async () => {
          if (restriction) {
            if (this.$store.state.myCourse === null) {
              await this.getMyCourse().then(response => {
                self.$store.commit('setMyCourse', response.lessons)
              })
            }
          }
          return restriction
        })(),
        (async () => {
          if (Object.keys(self.$store.state.lessonMaster).length === 0) {
            await self.getLessonMaster().then(response => {
              self.$store.commit('setLessonMaster', response.master)
            })
          }
          return self.$store.state.lessonMaster
        })(),
        (async () => {
          if (Object.keys(self.$store.state.lessonRelation).length === 0) {
            await self.getLessonRelationMaster().then(relation => {
              self.$store.commit('setLessonRelation', relation.master.enable_lessons)
            })
          }
          return self.$store.state.lessonRelation
        })()
      ])

      restrictionList = restriction ? this.$store.state.myCourse.map(v => v.school_id + '/' + v.course_id + '/' + v.class_id + '/' + v.lesson_id) : []
      const master = this.$store.state.lessonMaster
      let relation = []
      console.log(mode)
      switch (mode) {
        case 0:
          relation = this.$store.state.lessonRelation
          break
        case 1:
          relation = this.$store.state.lessonRelationRequestAvailable
          break
        case 2:
          relation = this.$store.state.lessonRelationYearUpdateOnly
          break
        case 3:
          relation = this.$store.state.lessonRelationcAdminOnly
          break
        default:
          relation = this.$store.state.lessonRelation
          break
      }

      schoolRelationMaster = relation.filter(v => {
        let courseF = false
        let classF = false
        let lessonF = false
        let restrictionF = true

        if (!selCourse || parseInt(selCourse) === v.course_id) {
          courseF = true
        }
        if (!selClass || parseInt(selClass) === v.class_id) {
          classF = true
        }
        if (!selLesson || parseInt(selLesson) === v.lesson_id) {
          lessonF = true
        }
        if (restrictionList.length > 0) {
          restrictionF = restrictionList.filter(w => w === v.school_id + '/' + v.course_id + '/' + v.class_id + '/' + v.lesson_id).length > 0
        }
        return courseF && classF && lessonF && restrictionF
      })
      courseRelationMaster = relation.filter(v => {
        let schoolF = false
        let classF = false
        let lessonF = false
        let restrictionF = true

        if (!selSchool || parseInt(selSchool) === v.school_id) {
          schoolF = true
        }
        if (!selClass || parseInt(selClass) === v.class_id) {
          classF = true
        }
        if (!selLesson || parseInt(selLesson) === v.lesson_id) {
          lessonF = true
        }
        if (restrictionList.length > 0) {
          restrictionF = restrictionList.filter(w => w === v.school_id + '/' + v.course_id + '/' + v.class_id + '/' + v.lesson_id).length > 0
        }
        return schoolF && classF && lessonF && restrictionF
      })
      classRelationMaster = relation.filter(v => {
        let schoolF = false
        let courseF = false
        let lessonF = false
        let restrictionF = true

        if (!selSchool || parseInt(selSchool) === v.school_id) {
          schoolF = true
        }
        if (!selCourse || parseInt(selCourse) === v.course_id) {
          courseF = true
        }
        if (!selLesson || parseInt(selLesson) === v.lesson_id) {
          lessonF = true
        }
        if (restrictionList.length > 0) {
          restrictionF = restrictionList.filter(w => w === v.school_id + '/' + v.course_id + '/' + v.class_id + '/' + v.lesson_id).length > 0
        }
        return schoolF && courseF && lessonF && restrictionF
      })
      lessonRelationMaster = relation.filter(v => {
        let schoolF = false
        let courseF = false
        let classF = false
        let restrictionF = true

        if (!selSchool || parseInt(selSchool) === v.school_id) {
          schoolF = true
        }
        if (!selCourse || parseInt(selCourse) === v.course_id) {
          courseF = true
        }
        if (!selClass || parseInt(selClass) === v.class_id) {
          classF = true
        }
        if (restrictionList.length > 0) {
          restrictionF = restrictionList.filter(w => w === v.school_id + '/' + v.course_id + '/' + v.class_id + '/' + v.lesson_id).length > 0
        }
        return schoolF && courseF && classF && restrictionF
      })

      await Promise.all(master.school.map(v => {
        if (schoolRelationMaster.some(w => w.school_id === v.school_id)) {
          schoolList.push({
            value: v.school_id,
            text: v.school_name,
            place: v.facility_name
          })
        }
        return v
      }))

      await Promise.all(master.course.map(v => {
        if (courseRelationMaster.some(w => w.course_id === v.course_id)) {
          courseList.push({
            value: v.course_id,
            text: v.course_name
          })
        }
        return v
      }))

      await Promise.all(master.class.sort((a, b) => {
        let returnNum
        const aGradeFrom = a.target_grade_from
        const bGradeFrom = b.target_grade_from
        const aFromToDiff = a.target_grade_to - a.target_grade_from
        const bFromToDiff = b.target_grade_to - b.target_grade_from
        if (aGradeFrom === bGradeFrom) {
          if (aFromToDiff === bFromToDiff) {
            returnNum = a.class_id > b.class_id ? 1 : -1
          } else {
            returnNum = aFromToDiff > bFromToDiff ? 1 : -1
          }
        } else {
          returnNum = aGradeFrom > bGradeFrom ? 1 : -1
        }
        return returnNum
      }).map(v => {
        if (classRelationMaster.some(w => w.class_id === v.class_id)) {
          classList.push({
            value: v.class_id,
            text: v.class_name,
            targetFrom: v.target_grade_from,
            targetTo: v.target_grade_to
          })
        }
        return v
      }))

      await Promise.all(master.lesson.sort((a, b) => {
        let returnNum = 0
        const aDayOne = a.lessons_day[0].day_id
        const bDayOne = b.lessons_day[0].day_id
        const aDaysLength = a.lessons_day.length
        const bDaysLength = b.lessons_day.length
        const aDays = a.lessons_day.map(v => v.day_id).join(',')
        const bDays = b.lessons_day.map(v => v.day_id).join(',')
        const aIsOr = a.lessons_day.map(v => v.is_target).includes(0)
        const bIsOr = b.lessons_day.map(v => v.is_target).includes(0)
        const aTrainingTime = a.training_start_time.split(':').map(v => ('00' + v).slice(-2)).join(':') + '~' + a.training_end_time.split(':').map(v => ('00' + v).slice(-2)).join(':')
        const bTrainingTime = b.training_start_time.split(':').map(v => ('00' + v).slice(-2)).join(':') + '~' + b.training_end_time.split(':').map(v => ('00' + v).slice(-2)).join(':')
        if (aDayOne === bDayOne) {
          if (aDaysLength === bDaysLength) {
            if (aDays === bDays) {
              if (aIsOr === bIsOr) {
                returnNum = aTrainingTime > bTrainingTime ? 1 : -1
              } else {
                returnNum = aIsOr ? 1 : -1
              }
            } else {
              returnNum = aDays > bDays ? 1 : -1
            }
          } else {
            returnNum = aDaysLength > bDaysLength ? 1 : -1
          }
        } else {
          returnNum = aDayOne > bDayOne ? 1 : -1
        }
        return returnNum
      }).map(v => {
        if (lessonRelationMaster.some(w => w.lesson_id === v.lesson_id)) {
          lessonList.push({
            value: v.lesson_id,
            text: v.lesson_name + ' ' + v.training_time,
            trainingTime: v.training_time,
            name: v.lesson_name
          })
        }
        return v
      }))

      return {
        school: schoolList,
        course: courseList,
        class: classList,
        lesson: lessonList
      }
    },
    // レッスンオブジェクトから表示用のデータに変更する。
    globalLessonExchange: function (lessonObject) {
      if (Object.keys(lessonObject).length === 0) {
        return {
          id: null,
          text: '',
          status: null,
          statusText: '',
          isRecess: '',
          willRecess: '',
          willJoin: '',
          willWithdraw: ''
        }
      }
      return {
        id: lessonObject.school_id + '/' + lessonObject.course_id + '/' + lessonObject.class_id + '/' + lessonObject.lesson_id,
        text: lessonObject.school_name + ' / ' + lessonObject.course_name + ' / ' + lessonObject.class_name + ' / ' + lessonObject.lesson_name,
        status: lessonObject.status,
        statusText: this.lessonStatusList[lessonObject.status],
        isRecess: lessonObject.is_recess ? '休会中' : '',
        willRecess: lessonObject.recess_date ? ((this.moment(lessonObject.recess_date).toString() !== 'Invalid date' && (this.moment(lessonObject.recess_date).format('YYYYMMDD') > this.moment().format('YYYYMMDD'))) ? this.moment(lessonObject.recess_date).format('YYYY/MM/DD') : false) : null,
        willJoin: lessonObject.join_date ? ((this.moment(lessonObject.join_date).toString() !== 'Invalid date' && (this.moment(lessonObject.join_date).format('YYYYMMDD') > this.moment().format('YYYYMMDD'))) ? this.moment(lessonObject.join_date).format('YYYY/MM/DD') : false) : null,
        willWithdraw: lessonObject.withdraw_date ? ((this.moment(lessonObject.withdraw_date).toString() !== 'Invalid date' && (this.moment(lessonObject.withdraw_date).format('YYYYMMDD') >= this.moment().format('YYYYMMDD'))) ? this.moment(lessonObject.withdraw_date).format('YYYY/MM/DD') : false) : null
      }
    },
    // レッスンから予定をセレクトアイテムの形��返す
    globalGetLessonSchedule: async function (schoolId, courseId, classId, lessonId) {
      const self = this
      const param = {
        school_id: schoolId,
        course_id: courseId,
        class_id: classId,
        lesson_id: lessonId
      }
      const returnList = []
      await this.getCourseScheduleData(param).then(response => {
        if (typeof response === 'object' && response.schedules) {
          response.schedules.map(v => {
            const dateText = self.moment(v.start_date.replace('Z', '')).format('YYYYMMDD') === self.moment(v.end_date.replace('Z', '')).format('YYYYMMDD')
              ? self.moment(v.start_date.replace('Z', '')).format('YYYY/MM/DD HH:mm') + ' ～ ' + self.moment(v.end_date.replace('Z', '')).format('HH:mm')
              : self.moment(v.start_date.replace('Z', '')).format('YYYY/MM/DD HH:mm') + ' ～ ' + self.moment(v.end_date.replace('Z', '')).format('YYYY/MM/DD HH:mm')
            returnList.push({
              value: v.id,
              text: dateText,
              isDeleted: 0,
              isHolding: v.is_holding
            })
          })
        }
      }).catch(e => {
        // todo: エラー処理
        console.log(e)
      })

      return returnList
    },
    // フ���イルデータからファイルをS3にアップロードする。
    globalFileUpload: async function (file, folderName) {
      console.log(file)
      if (file.length === 0) {
        return []
      }

      const fileInfo = []
      const tmpFile = []
      const fixFileNameList = []
      await Promise.all(file.map(async function (v) {
        if (!v) {
          return 0
        }
        console.log(v)
        let fileNameFix = ''
        if (tmpFile.filter(w => v.name === w).length === 0) {
          fileNameFix = v.name
        } else {
          fileNameFix = '(' + (tmpFile.filter(w => v.name === w).length + 1) + ')' + v.name
        }
        tmpFile.push(v.name)
        fixFileNameList.push(fileNameFix)

        const fileName = folderName + '/' + fileNameFix.normalize('NFC')
        let uploadFileKey
        await Storage.put(fileName, v).then(uploadFile => {
          console.log(uploadFile)
          uploadFileKey = uploadFile.key
        }).catch(e => {
          const logger = new Logger()
          logger.error('error happened', e)
          console.log(e)
        })
        console.log(uploadFileKey)

        await Storage.get(uploadFileKey).then(url => {
          fileInfo.push({
            url: url,
            uploadName: fileName,
            name: fileNameFix
          })
        })
        return fileInfo.length
      }))

      const returnList = fixFileNameList.map(v => {
        console.log(v)
        return fileInfo.find(w => w.name === v)
      })
      return returnList
    },
    // アップロードしてあるファイルのDLURLを取得する。
    globalGetFile: async function (fileName) {
      let returnUrl = null
      if (!fileName) {
        return null
      }
      console.log(fileName.normalize('NFC'), fileName, fileName.normalize('NFC') === fileName)
      await Storage.get(fileName).then(url => {
        returnUrl = url
      }).catch(e => {
        console.log('sssss' + e)
      })
      console.log(returnUrl)
      return returnUrl
    },
    // アップロードしてあるファイルのLISTを取得する。
    globalGetFileList: async function (directoryName) {
      let returnInfo = null
      if (!directoryName) {
        return null
      }
      await Storage.list(directoryName).then(info => {
        returnInfo = info
      }).catch(e => {
        console.log(e)
      })
      return returnInfo
    },
    globalDeleteFileList: async function (fileNameList) {
      let returnResponse = null
      if (!fileNameList.length === 0) {
        return null
      }
      fileNameList.map(v => {
        Storage.remove(v.normalize('NFC')).then(response => {
          returnResponse = response
        })
      })
      return returnResponse
    },
    // アップロード用のファイル名から元のファイル名を復元する
    globalGetUploadFileName: function (fileName) {
      const fileNameArray = fileName.split('/')
      if (fileNameArray[0] === 'event') {
        fileNameArray.shift()
      }
      fileNameArray.shift()
      return fileNameArray.join('/').normalize('NFC')
    },
    // アップロードしてあるmanualファイルのDLURLを取得する。
    globalGetManual: async function (fileName) {
      let returnUrl = null
      if (!fileName) {
        return null
      }
      console.log(fileName)
      await Storage.get('manual/' + fileName).then(url => {
        returnUrl = url
      })
      console.log(returnUrl)
      return returnUrl
    },
    // アップロードしてある画像ファイルを取得する。
    globalGetImg: async function (type, fileName, id) {
      let returnUrl = null
      if (!fileName) {
        return null
      }
      console.log(fileName)
      await Storage.get('img/' + type + '/' + id + '_' + fileName).then(url => {
        returnUrl = url
      })
      console.log(returnUrl)
      return returnUrl
    },
    // コースとかの画像アップロード
    globalPostImg: async function (type, file, id) {
      let returnResponse = null
      if (!file) {
        return null
      }
      await Storage.put('img/' + type + '/' + (id + '_' + file.name).normalize('NFC'), file).then(response => {
        returnResponse = response
      })
      return returnResponse
    },
    // コースとかの画像削除
    globalDeleteImg: async function (type, fileName, id) {
      let returnResponse = null
      if (!fileName) {
        return null
      }
      await Storage.remove('img/' + type + '/' + (id + '_' + fileName).normalize('NFC')).then(response => {
        returnResponse = response
      })
      return returnResponse
    },
    // csvダウンロード
    // colTitle = ['A', 'B']
    // colContent = [
    //   ['A-1', 'B-1'],
    //   ['A-2', 'B-2']
    // ]
    globalCsvDownload: function (fileTitle, colTitle, colContent) {
      this.$store.commit('startLoading')
      let csv = '\ufeff' + '"' + colTitle.join('","') + '"' + '\n'
      colContent.map(v => {
        csv += '"' + Object.values(v).join('","') + '"' + '\n'
      })
      const blob = new Blob([csv], { type: 'text/csv' }) /* global Blob */
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = this.globalTeamNameAbbreviation + '_' + fileTitle + '_' + this.moment().format('YYYYMMDD_HHmmss') + '.csv'
      link.click()
      this.$store.commit('finishLoading')
    },
    // 各IDからenableLssonsIdを取得する。
    globalGetEnableLssonsId: async function (schoolId, courseId, classId, lessonId) {
      const self = this
      let lessonsInfo = []

      if (this.$store.state.lessonRelation.length === 0) {
        await this.getLessonRelationMaster().then(relation => {
          self.$store.commit('setLessonRelation', relation.master.enable_lessons)
        })
      }

      // まずは削除フラグが立っていないものを探す。
      lessonsInfo = this.$store.state.lessonRelation.filter(v => {
        return v.school_id === schoolId && v.course_id === courseId && v.class_id === classId && v.lesson_id === lessonId
      })

      // 削除フラグが立っていないもので見つからなかった場合全検索
      if (lessonsInfo.length === 0) {
        lessonsInfo = this.$store.state.lessonRelationAll.filter(v => {
          return v.school_id === schoolId && v.course_id === courseId && v.class_id === classId && v.lesson_id === lessonId
        })
      }

      return lessonsInfo.length === 0 ? null : lessonsInfo[0]
    },
    // enableIdからそれぞれのIDを取得する。
    globalGetLessonsId: async function (enableLessonId) {
      const self = this
      let lessonsInfo = []

      if (this.$store.state.lessonRelation.length === 0) {
        await this.getLessonRelationMaster().then(relation => {
          self.$store.commit('setLessonRelation', relation.master.enable_lessons)
        })
      }

      lessonsInfo = this.$store.state.lessonRelationAll.filter(v => enableLessonId === v.id)

      return lessonsInfo.length === 0 ? null : lessonsInfo[0]
    },
    globalGetBillItem: async function (category) {
      let returnList = []
      await this.getBillItem().then(response => {
        returnList = response.master.bill_expense_item.filter(v => v.bill_category === category)
      }).catch(e => {
        // todo: エラー処理
        console.log(e)
      })
      return returnList
    },
    // yearOverFlag = 年度跨ぎのを許可するか
    // sameDayFlag = 即日●●を許可するか
    // sameDayCloseDate = ぴあ側の締め日
    globalGetCourseReqestInfo: async function (yearOverFlag, sameDayFlag) {
      const self = this
      const today = this.moment()
      const returnObject = {
        dateMax: '',
        dateMin: '',
        defDate: '',
        closingDateAft: false
      }
      this.$store.commit('startLoading')
      await Promise.all([
        (async () => {
          if (self.$store.state.billClosingDateMaster.length === 0) {
            await self.getBillClosingDateMaster().then(response => {
              self.$store.commit('setBillClosingDateMaster', response)
            })
          }
          return self.$store.state.billClosingDateMaster
        })(),
        (async () => {
          if (!self.$store.state.nextYearUpdateStartDate) {
            await self.getNextYearUpdateDateSpan().then(response => {
              self.$store.commit('setNextYearUpdateDateSpan', response)
            })
          }
          return self.$store.state.nextYearUpdateStartDate
        })()
      ])
      this.$store.commit('finishLoading')

      // 当月の締め日
      const todayCloseDate = this.moment(this.$store.state.billClosingDateMaster.find(v => this.moment(v.closing_date).month() === today.month() && this.moment(v.closing_date).year() === today.year()).closing_date).date()
      // 当月の即日申請締め日
      const sameDayCloseDate = this.moment(this.$store.state.billClosingDateMaster.find(v => this.moment(v.confirm_closing_date).month() === today.month() && this.moment(v.confirm_closing_date).year() === today.year()).confirm_closing_date).date()
      // 年度切り替え月
      const aprilMonth = this.$store.state.nextYearUpdateEndDate ? this.moment(this.$store.state.nextYearUpdateEndDate).format('MM') : null
      // 次年度の年度切り替え年月日
      const nextYearApril = aprilMonth ? (today.format('MM-DD') < this.moment(this.$store.state.nextYearUpdateStartDate).format('MM-DD') ? this.moment(today.format('YYYY') + aprilMonth + '01').format('YYYY-MM-DD') : this.moment(this.moment().add(1, 'y').format('YYYY') + aprilMonth + '01').format('YYYY-MM-DD')) : null
      // 締め日が登録されている最高年月日
      const billClosingDateMax = this.$store.state.billClosingDateMaster[this.$store.state.billClosingDateMaster.length - 1].target_date

      // 年度更新期間が設定されてない場合、年度の境目がわからないため自動的に年度跨ぎを許可する。
      if (yearOverFlag || !nextYearApril) {
        returnObject.dateMax = this.moment(billClosingDateMax).format('YYYY-MM-DD')
      } else {
        returnObject.dateMax = this.moment(billClosingDateMax).format('YYYY-MM-DD') < nextYearApril ? this.moment(billClosingDateMax).format('YYYY-MM-DD') : this.moment(nextYearApril).subtract(1, 'M').format('YYYY-MM-DD')
      }

      if (sameDayFlag) {
        returnObject.dateMin = today.date() < sameDayCloseDate ? today.format('YYYY-MM-01') : this.moment().add(1, 'M').format('YYYY-MM-01')
      } else {
        returnObject.dateMin = today.date() < todayCloseDate ? today.format('YYYY-MM-01') : this.moment().add(1, 'M').format('YYYY-MM-01')
      }

      // 当月の締め日前 = 当月
      // 当月の締め日以降 = 次月
      returnObject.defDate = today.date() < todayCloseDate ? today.format('YYYY-MM-01') : this.moment().add(1, 'M').format('YYYY-MM-01')
      returnObject.closingDateAft = !(today.date() < todayCloseDate)

      console.log(todayCloseDate, sameDayCloseDate, aprilMonth, nextYearApril, billClosingDateMax, today.date(), todayCloseDate, this.moment(this.$store.state.billClosingDateMaster.find(v => this.moment(v.closing_date).month() === today.month()).closing_date))
      console.log(returnObject)
      return returnObject
    },
    globalYoutubeIframeEn: function (html) {
      if (!html) {
        return ''
      }
      return html.replace(/<iframe class="ql-video"/g, '＜****** *****="**-****"').replace(/<\/iframe>/g, '＜/******＞')
    },
    globalYoutubeIframeDe: function (html) {
      if (!html) {
        return ''
      }
      return html.replace(/＜\*\*\*\*\*\* \*\*\*\*\*="\*\*-\*\*\*\*"/g, '<iframe class="ql-video"').replace(/＜\/\*\*\*\*\*\*＞/g, '</iframe>')
    },
    globalHttpError: function (error) {
      console.log(JSON.parse(JSON.stringify(error)))
      if (!error.response) {
        if (JSON.parse(JSON.stringify(error)).message && JSON.parse(JSON.stringify(error)).message.split(' ')[0] === 'timeout') {
          this.$store.commit('initLoading')
          const snackBarInfo = {
            text: '電波状況の良いところで再度お試しください。',
            color: 'error'
          }
          this.$store.commit('setSnackbar', snackBarInfo)
        } else {
          const snackBarInfo = {
            text: '通信に失敗しました。電波状況の良いところで再度お試しいただき、改善しない場合はお問い合わせください。',
            color: 'error'
          }
          this.$store.commit('initLoading')
          // this.$router.push({ name: 'sorry' })
          this.$store.commit('setSnackbar', snackBarInfo)
        }
      } else {
        if (error.response.status === 401 || error.response.status === 500) {
          this.$store.commit('initLoading')
          const snackBarInfo = {
            text: '画面を読み込み直して、再度お試しください。',
            color: 'error'
          }
          this.$store.commit('setSnackbar', snackBarInfo)
        } else if (error.response.status === 408) {
          this.$store.commit('initLoading')
          const snackBarInfo = {
            text: '電波状況の良いところで再度お試しください。',
            color: 'error'
          }
          this.$store.commit('setSnackbar', snackBarInfo)
        } else {
          const snackBarInfo = {
            text: '通信に失敗しました。',
            color: 'error'
          }
          this.$store.commit('initLoading')
          this.$router.push({ name: 'sorry' })
          this.$store.commit('setSnackbar', snackBarInfo)
        }
      }
    },
    globalApiError: function (message) {
      console.log(message)
      const snackBarInfo = {
        text: message + '再度お試しいただいても改善しない場合はお問い合わせください。',
        color: 'error'
      }
      this.$store.commit('initLoading')
      this.$store.commit('setSnackbar', snackBarInfo)
    },
    globalShowDateSpan: function (startDate, endDate) {
      const sy = this.moment(startDate).format('YYYY')
      const sm = this.moment(startDate).format('MM')
      const sd = this.moment(startDate).format('DD')
      const ey = this.moment(endDate).format('YYYY')
      const em = this.moment(endDate).format('MM')
      const ed = this.moment(endDate).format('DD')

      return this.moment(startDate).format('YYYY/MM/DD HH:mm') + ' ~ ' + ((sd === ed && sm === em && sy === ey) ? this.moment(endDate).format('HH:mm') : sy === ey ? this.moment(endDate).format('MM/DD HH:mm') : this.moment(endDate).format('YYYY/MM/DD HH:mm'))
    },
    localLoadingStart: function () {
      this.localLoading.push(true)
    },
    localLoadingFinish: function () {
      this.localLoading.pop()
    },
    moment: function (dataString = '') {
      return dataString ? momentDate(dataString) : momentDate()
    },
    globalDatePickerUpdateViewMode: function (modeValue, limitMode) {
      if (limitMode === 'month') {
        if (modeValue === 'month') {
          return 'months'
        } else {
          return modeValue
        }
      } else if (limitMode === 'year') {
        return 'year'
      } else {
        return modeValue
      }
    },
    globalFileDownloadBlob: async function (fileUrl, fileName) {
      let getUrl = null
      let returnBlob = null
      console.log(fileUrl, fileName)
      await this.globalGetFile(fileUrl).then(url => {
        getUrl = url
      })
      console.log(getUrl)
      await axios.get(getUrl, {
        responseType: 'blob'
      }).then(response => {
        console.log(response)
        returnBlob = new Blob([response.data], { type: this.fileMimeTypeList.find(v => v.value === fileName.split('.')[fileName.split('.').length - 1].toLowerCase()).type })
      })
      return returnBlob
    }
  }
}
